<template>
  <article class="expediente">
    <aside>

      <header>
        <h2>Servicio farmacéutico Rionegro - Expediente {{ this.id }}</h2>
        <article>
          <div>
            <label>Estado:</label>
            <p>{{ expediente.estado }}</p>
          </div>
          <div>
            <label>Documento:</label>
            <p>{{ expediente.afiliado.numeroDocumento }}</p>
          </div>
          <div>
            <label>Afiliado:</label>
            <p>{{ expediente.afiliado.nombreCompleto }}</p>
          </div>
          <div>
            <label>Fecha de creación:</label>
            <p>{{ expediente.createDate.substring(0,expediente.createDate.indexOf("T")) }}</p>
          </div>
          <div>
            <label>Código interno:</label>
            <p>15454-{{ this.id }}</p>
          </div>
          <div>
            <label>Contrato:</label>
            <p>SAVIA</p>
          </div>
        </article>
        <footer>
          <v-btn variant="elevated" color="primary" @click="cambiarEstado()">
            Cambiar estado
          </v-btn>
          <v-btn variant="elevated" color="primary" @click="cambiarVista">
            <v-icon class="me-2">change_circle</v-icon>
          </v-btn>
        </footer>
      </header>
      <section v-if="vista">
        <ul>
          <li v-for="(archivo, index) in expediente.archivos" v-bind:key="index">
            <aside>
              <figure class="ico-pdf">
                <v-icon @click="toggleClass" class="me-2">picture_as_pdf</v-icon>
              </figure>
            </aside>
            <article>
              <!-- <h3>{{archivo.nombreArchivo}}</h3> -->
              <h4 v-if="archivo.tipoArchivo=='ATTACHMENT'">Formula</h4>
              <h4 v-else-if="archivo.tipoArchivo=='INVOICE'">Factura</h4>
              <h4 v-else-if="archivo.tipoArchivo=='HISTORY'">Hoja Entrega</h4>
              <h4 v-else-if="archivo.tipoArchivo=='ORDER'">Autorizacion</h4>
              <h4 v-else-if="archivo.tipoArchivo=='ATTENTION'">Comprobante</h4>
              <h4 v-else-if="archivo.tipoArchivo=='OTHER'">Otro adjunto</h4>
              <h5>{{ formatFecha(expediente.createDate) }} - {{ formatFecha2(expediente.createDate) }}</h5>
              <figure>
                <v-icon class="me-2" @click="verExpediente(archivo)" title="Ver">open_in_new</v-icon>
              </figure>
              <figure>
                <v-icon class="me-2" @click="descargarArchivo(archivo)" title="Descargar">download</v-icon>
              </figure>
            </article>
          </li>
          <li class="add" @click="dialog = true">
            <aside>
              <figure>
                <v-icon class="me-2">add</v-icon>
              </figure>
            </aside>
          </li>
        </ul>
      </section>
      <section v-if="!vista">
        <v-container>
          <v-row>
            <v-col cols="12">
              <div class="list-header">
                <div class="list-cell icon"></div>
                <div class="list-cell">Tipo</div>
                <div class="list-cell">Fecha</div>
                <div class="list-cell actions">Acciones</div>
              </div>
            </v-col>

            <v-col cols="12" v-for="(archivo, index) in expediente.archivos" :key="index">
              <div class="list-row">
                <div class="list-cell icon" @click="verExpediente(archivo)">
                  <v-icon class="me-2" @click="toggleClass">picture_as_pdf</v-icon>
                </div>
                <div class="list-cell" @click="verExpediente(archivo)">
                  <span v-if="archivo.tipoArchivo === 'ATTACHMENT'">Fórmula</span>
                  <span v-else-if="archivo.tipoArchivo === 'INVOICE'">Factura</span>
                  <span v-else-if="archivo.tipoArchivo === 'HISTORY'">Hoja de Entrega</span>
                  <span v-else-if="archivo.tipoArchivo === 'ORDER'">Autorización</span>
                  <span v-else-if="archivo.tipoArchivo === 'ATTENTION'">Comprobante</span>
                  <span v-else>Otro Adjunto</span>
                </div>
                <div class="list-cell" @click="verExpediente(archivo)">{{ formatFecha(expediente.createDate) }} {{ formatFecha2(expediente.createDate) }}</div>
                <div class="list-cell actions">
                  <!-- <v-icon class="me-2"  title="Ver">open_in_new</v-icon> -->
                  <v-icon class="me-2" @click="descargarArchivo(archivo)" title="Descargar">download</v-icon>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </section>
      <footer>
        <v-btn variant="elevated" color="secundary" @click="dialog = true" v-if="!vista">
          Cargar Documento
        </v-btn>
        <v-btn variant="elevated" color="primary" @click="descargarExpediente">
          Descargar expediente
        </v-btn>
      </footer>
    </aside>
    <section class="expediente-pdf expediente-pdf-active">
      <div id="pdf-viewer" v-if="loadingPdf==3"></div> 
      <article v-if="loadingPdf==1">
        <h3>Visualizar Documentos</h3>
      </article>
      <section v-if="loadingPdf==2">
        <div class="loading">
          <div class="loadingio-spinner-spinner-2by998twmg8"><div class="ldio-yzaezf3dcmj">
            <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
            </div></div>
            <h4>Cargando Documento</h4>
        </div>  
      </section>
    </section>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title class="headline">Seleccionar y Subir Archivo</v-card-title>
        <v-card-text>          
          <v-select
            v-model="selectedType"
            :items="fileTypes"
            label="Seleccionar Tipo de Archivo"
            outlined
          ></v-select>          
          <div
            class="file-drop-zone"
            @drop.prevent="handleFileDrop"
            @dragover.prevent
          >
            <v-file-input
              v-model="selectedFile"
              label="Arrastra o selecciona un archivo"
              accept=".jpg,.png,.pdf"
              outlined
            ></v-file-input>
          </div>
        </v-card-text>        
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="uploadFile">Subir</v-btn>
          <v-btn color="outlined" text @click="dialog = false">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog2" persistent max-width="500px">
      <v-card>
        <v-card-title class="headline">Cambiar Estado del Expediente</v-card-title>
        
        <v-card-text>          
          <v-select
            v-model="selectedStatus"
            :items="statusOptions"
            label="Seleccione Estado"
            outlined
            @change="handleStatusChange"
          ></v-select>
          
          <!-- <v-textarea
            v-if="selectedStatus === 'Revisar por Servicio Farmaceutico'"
            v-model="observation"
            label="Observación"
            outlined
            rows="3"
          ></v-textarea> -->
        </v-card-text>
        
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="saveStatus">Guardar</v-btn>
          <v-btn color="red darken-1" text @click="dialog2 = false">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </article>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import PDFObject from 'pdfobject';
import Swal from 'sweetalert2'
import moment from 'moment';

export default {
  name: 'Index',
  data: () => ({
    tab: null,
    rolesKeycloak: null,
    codigosEmpresas: [],
    dataEmpresas: [],
    usuario: "",
    id: "",
    loadingPdf: 1,
    expediente: {
      archivos: [],
      afiliado: {},
      createDate: ""
    },

    pdfUrl: null,  
    isActive: false,
    vista: true,
    isAdmin: false,

    dialog2: false,
    observation: '', 
    selectedStatus: null, 
    statusOptions: [
      'AUDITADO',
      'COMPLETO',
      'RADICADO',
      'DEVUELTO',
    ],

    dialog: false, 
    selectedType: null, 
    selectedFile: null, 
    fileTypes: ['Formula', 'Factura', 'Hoja Entrega', "Autorizacion", "Comprobante", "Otro adjunto"], 
  }),
  mounted() {
     
    this.usuario = this.auth.username;
    //this.empresasUsuario();
    this.id = this.$route.params.id;
    this.getBodega();
    this.getExpediente();
    this.embedPDF();

    const vuexState = JSON.parse(localStorage.getItem('vuex'));

    const savedVista = localStorage.getItem("vista-expediente");
    this.vista = savedVista !== null ? JSON.parse(savedVista) : true;
    
    if(vuexState.auth.roles.includes("Expediente_Administrador")){
      this.isAdmin=true;
    }
    if(vuexState.auth.roles.includes("Expediente_Digitalizador")){
      this.addTitle=" - Digitalizador"
    }
    if(vuexState.auth.roles.includes("Expediente_Auditor")){
      this.addTitle=" - Auditor";
      this.statusOptions= [
        'AUDITADO',
        'DEVUELTO',
      ];
    }
    if(vuexState.auth.roles.includes("Expediente_Administrador")){
      this.addTitle=" - Radicador";
      this.statusOptions= [
        'RADICADO',
        'DEVUELTO',
      ];
    }
  },
  computed: {
    ...mapState(["auth", "enterprise"]),
  },

  methods: {
    ...mapMutations(["setEnterprise"]),
    async empresasUsuario() {
      const codEmpresas = await this.$http.get(`msa-setting/api/usem/relacion/${this.usuario}`)
        .then(({ data }) => this.codigosEmpresas = data)
        .catch(err => console.log(err))

      if (codEmpresas != []) {
        await this.$http.post('msa-setting/api/empresa/list', this.codigosEmpresas)
          .then(({ data }) => this.dataEmpresas = data)
          .catch(err => console.log(err))
      }
    },
    toggleClass() {
      this.isActive = !this.isActive; 
    },
    getExpediente() {
      this.$http.get(`/msa-process-file/api/expediente/mostrarExpediente?idEmpresa=1&idExpediente=${this.id}`)
        .then(({ data }) => {
          console.log(data);
          this.expediente=data;
        })
        .catch(err => console.log(err))
    },
    formatFecha(fecha) {
      return moment(fecha).format('YYYY/MM/DD');
    },
    formatFecha2(fecha) {
      return moment(fecha).format('HH:mm');
    },
    toEnterprise(enterprise) {
      this.auth.roles = this.rolesKeycloak;
      this.setEnterprise(enterprise)
    },
    embedPDF() {        
      PDFObject.embed(this.pdfUrl, "#pdf-viewer");
    },
    getBodega() {
      this.$http.get(`msa-process-file/api/file/getBodegaUsuario`)
        .then(({ data }) => {
          console.log(data);
          this.bodegaId=data;
        })
        .catch(err => console.log(err))
    },
    closePdf(){
      this.isActive=false;
    },
    verExpediente(archivo){
      console.log(archivo);
      this.pdfUrl="";
      this.loadingPdf=2;
      this.$http.get(`msa-process-file/api/file/getFile?fileName=${this.bodegaId}/expediente/${this.expediente.afiliado.numeroDocumento}/${this.expediente.idExpediente}/${archivo.nombreArchivo}`)
      .then(({ data }) => {
        console.log(data);
        this.loadingPdf=3;
        this.pdfUrl="data:application/pdf;base64,"+data;
        this.isActive=true;
        setTimeout(()=>{
          this.embedPDF();
        },100)
      })
      .catch(err => console.log(err))           
    },
    descargarExpediente(archivo){
      console.log(archivo);
      
      this.$http.get(`msa-process-file/api/expediente/descargarExpediente?fileName=${this.bodegaId}/expediente/${this.expediente.afiliado.numeroDocumento}/${this.expediente.idExpediente}`,      
        {
          responseType: 'arraybuffer'
        }
      )
        .then(({ data }) => {
          console.log(data);
          const fileBlob = new Blob([data], { type: 'application/zip' });

          const link = document.createElement('a');
          const url = window.URL.createObjectURL(fileBlob);
          
          link.href = url;
          link.setAttribute('download', 'archivo.zip'); 
          
          document.body.appendChild(link);
          link.click();
          
          link.remove();
          window.URL.revokeObjectURL(url);
        })
        .catch(err => console.log(err))
    },
    descargarArchivo(archivo) {
      console.log(archivo);
      
      this.$http.get(`msa-process-file/api/expediente/descargarArchivo?fileName=${this.bodegaId}/expediente/${this.expediente.afiliado.numeroDocumento}/${this.expediente.idExpediente}/${archivo.nombreArchivo}`,
        {
          responseType: 'arraybuffer'
        }
      )
        .then(({ data }) => {
          console.log(data);
          // const fileBlob = new Blob([data], { type: 'application/zip' });

          // const link = document.createElement('a');
          // const url = window.URL.createObjectURL(fileBlob);
          
          // link.href = url;
          // link.setAttribute('download', 'archivo.zip'); 
          
          // document.body.appendChild(link);
          // link.click();
          
          // link.remove();
          // window.URL.revokeObjectURL(url);
        })
        .catch(err => console.log(err))
    },
    handleFileDrop(event) {      
      const file = event.dataTransfer.files[0];
      this.selectedFile = file;
    },
    cambiarVista() {
      this.vista=!this.vista;
      console.log(this.vista);
      localStorage.setItem("vista-expediente", this.vista);
    },
    uploadFile() {
      if (this.selectedFile && this.selectedType) {        
        console.log('Subiendo archivo:', this.selectedFile);
        console.log('Tipo de archivo seleccionado:', this.selectedType); 
        const reader = new FileReader();          
        reader.readAsDataURL(this.selectedFile);
        
        reader.onload = () => {

          const base64String = reader.result;
          console.log('Archivo en Base64:', base64String);
          let tipoArchivo= "ATTACHMENT";
          if(this.selectedType=="Factura"){
            tipoArchivo= "INVOICE";
          }
          if(this.selectedType=="Hoja Entrega"){
            tipoArchivo= "HISTORY";
          }
          if(this.selectedType=="Autorizacion"){
            tipoArchivo= "ORDER";
          }
          if(this.selectedType=="Comprobante"){
            tipoArchivo= "ATTENTION";
          }
          if(this.selectedType=="Otro adjunto"){
            tipoArchivo= "OTHER";
          }

          console.log(this.expediente);                              
          this.$http.post(`msa-process-file/api/expediente/actualizarExpediente`,
            {
              tipoArchivo, 
              "bodega": {
                  "bodegasId": {
                      "idEmpresa": 1,
                      "codigoBodega": this.bodegaId
                  }
              },
              "documentoAfiliado": this.expediente.afiliado.numeroDocumento,
              "idExpediente": this.id,
              "nombreArchivo": this.selectedFile.name,
              "base64": base64String
            }
          )
          .then(({ data }) => {
            console.log(data);
            this.dialog = false;
            Swal.fire({
              title: 'Expediente',
              text: 'El archivo se ha sido almacenado en el expediente.',
              icon: 'success',
              confirmButtonText: 'Aceptar'
            });
            this.getExpediente();
            this.selectedType="";
            this.selectedFile=null;
          })
          .catch(err => console.log(err))     
        }
      } else {
        alert('Por favor, selecciona un tipo de archivo y sube uno.');
      }
    },
    cambiarEstado() {
      this.dialog2=true;
    },
    handleStatusChange() {      
      if (this.selectedStatus !== 'Revisar por Servicio Farmaceutico') {
        this.observation = '';
      }
    },
    saveStatus() {      
      
      let data= {
        estado: this.selectedStatus
      }

      this.$http
      .put("msa-process-file/api/expediente/estadoExpediente/"+this.expediente.idExpediente, data)
      .then((result) => {
        console.log("Estado: ", result);
        this.dialog = false;
        if(result.data.idExpediente){
          Swal.fire({
            title: 'Expediente',
            text: 'El estado del expediente ha sido actualizado',
            icon: 'success',
            confirmButtonText: 'Aceptar'
          });
          this.expedientes = this.expedientes.map(exp => {
            if (exp.idExpediente === this.expediente.idExpediente) {
              return { ...exp, estado: this.selectedStatus }; 
            }
            return exp; 
          });
          this.selectedStatus=null;
        }else{
          Swal.fire({
            title: 'Expediente',
            text: 'Hubo un error, intente nuevamente',
            icon: 'error',
            confirmButtonText: 'Aceptar'
          });
        }
      });
    },
  }
}
</script>

<style scoped>

ul, ol {
  list-style: none;
}


.file-drop-zone {
  border: 2px dashed #ccc;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  margin-top: 20px;
}

#pdf-viewer{
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

article.expediente {
  aspect-ratio: 1 / 1s;
  background: white;
  border-radius: 1rem;
  width: 100%;
  height: calc(100vh - 69px);
  background-color: #f5f5f5;
  display: flex;
}

article.expediente > aside  {
  width: 50%;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
}

article.expediente > aside header {
  padding: 1rem 0;  
  background: white;
  -webkit-box-shadow: 2px 2px 5px -1px rgba(0, 0, 0, 0.23);
  -moz-box-shadow: 2px 2px 5px -1px rgba(0, 0, 0, 0.23);
  box-shadow: 2px 2px 5px -1px rgba(0, 0, 0, 0.23);
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%
}
article.expediente > aside header h2 {
  margin-bottom: 1rem;
  text-align: center;
  width: 100%;
  font-size: 18px;
}

article.expediente > aside header article  {
  width: 100%;
}
article.expediente > aside header article div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: .2rem;
}

article.expediente > aside header article div label {
  font-weight: bold;
  margin-right: .4rem;
  text-align: right;
  width: 100%;
  font-size: 13px;
}

article.expediente > aside header article div p {
  width: 100%;
  text-align: left;
  font-weight: 400;
  margin: 0;
  font-size: 12px;
}

article.expediente > aside section {
  margin: 0;
  margin-top: 3px;
}

article.expediente section .ico-pdf button{
  font-size: 30px;
  color: #FF5733;
}

article.expediente i {
  margin-right: 0 !important;
}

article.expediente section ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  flex-wrap: wrap;
}

article.expediente section ul li {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;  
  border-radius: .5rem;
  border: .05rem solid #888;
  height: 10rem;
  margin-right: .5rem;
  margin-top: .5rem;
  padding: 1rem 0;
  padding-top: 0.3rem;
  width: 11rem;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  background-color: white;
}

article.expediente section ul li > aside {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  width: 100%;
}

article.expediente section ul li > aside figure {
  margin-top: .5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

article.expediente section ul li > aside figure i {
  font-size: 2.2rem;
}

article.expediente section ul li article {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;

}
article.expediente section ul li article h3 {
  color: #777;
  font-size: .9rem;
  font-weight: 400;
  text-align: center;
  width: 100%;
}
article.expediente section ul li article h4 {
  color: #777;
  font-size: .9rem;
  font-weight: 600;
  text-align: center;
  width: 100%;
}
article.expediente section ul li article h5 {
  color: #777;
  font-size: .9rem;
  font-weight: 400;
  text-align: center;
  width: 100%;
}

article.expediente section ul li article figure {
  margin-top: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #0d47a1;
  border-radius: 50%;
  margin-left: .5rem;
  cursor: pointer;

}

article.expediente section ul li article figure button {
  font-size: 1.2rem;
  color: #0d47a1;
  padding: .2rem;
  margin-right: 0 !important;
}

article.expediente section ul li.incompleta article figure {
  border: 1px solid orange;
  border-radius: 50%;
}

article.expediente section ul li.incompleta article figure i {
  color: orange;
}

article.expediente section ul li.add {
  position: relative;
  border: 0.05rem dashed #0d47a1;
  cursor: pointer;
  margin-right: 0 !important;
}

article.expediente section ul li.add aside {
  margin: 3rem 0;
}

article.expediente section ul li.add aside figure {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

article.expediente > aside > header > footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
article.expediente > aside > footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 50px;
  margin-top: 20px;
}

article.expediente footer button{
  margin: 0 1rem;
}



section.expediente-pdf article{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

section.expediente-pdf{
  width: 50%;
  height: 100%;
  right: -100%;
  top: 0;
  background-color: white;
  transition: all 2s;
  -webkit-box-shadow: -4px 0px 4px -1px rgba(0,0,0,0.44);
  -moz-box-shadow: -4px 0px 4px -1px rgba(0,0,0,0.44);
  box-shadow: -4px 0px 4px -1px rgba(0,0,0,0.44);
  position: relative;
}

section.expediente-pdf > header{
  position: absolute ;
  height: 100%;
  width: 40px;
  left: -40px;  
}

section.expediente-pdf > header .close-pdf{
  cursor: pointer;
  background: white
}

section.expediente-pdf-active{
  right: 0;
  transition: all 1s;
}

.list-cell {
  flex: 1;
  text-align: left;
  padding: 5px;
}

.list-cell:nth-child(1) {
  max-width: 60px;
}

.list-cell:nth-child(3) {
  max-width: 140px;
}

.list-cell:nth-child(4) {
  max-width: 80px;
}

.list-cell.icon {
  flex: 0.3; /* Espacio reservado para el icono */
  display: flex;
  align-items: center;
  justify-content: center;
}

.list-cell.actions {
  flex: 0.8; /* Espacio suficiente para los íconos de acciones */
}

.list-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font-weight: bold;
  background-color: #f5f5f5;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.list-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 0 10px;
  border-bottom: 1px solid #ddd;
  align-items: center;
}

.col:not(:first-child) .list-cell{
  cursor: pointer;
}

.row .actions{
  display: flex;
  justify-content: flex-end;
}
.row .col:not(:first-child):hover{
  background-color: white;
}

.list-row > .icon .v-icon.v-icon{
  color: #FF5733
}

.list-row > div{
  white-space: nowrap;
  overflow: hidden;
}

.row .col{
  padding: 0 2%;
}

.loading{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.5);
  z-index: 2;
}
.loading h4{
  width: 100%;
  text-align: center;
  color: white;
  font-size: 19px;
  margin-top: -20px;
  font-weight: 200;
}

@keyframes ldio-yzaezf3dcmj {
  0% { opacity: 1 }
  100% { opacity: 0 }
}
.ldio-yzaezf3dcmj div {
  left: 96px;
  top: 45px;
  position: absolute;
  animation: ldio-yzaezf3dcmj linear 1s infinite;
  background: #0d47a1;
  width: 8px;
  height: 18px;
  border-radius: 3.42px / 3.42px;
  transform-origin: 4px 55px;
}.ldio-yzaezf3dcmj div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -0.9375s;
  background: #0d47a1;
}.ldio-yzaezf3dcmj div:nth-child(2) {
  transform: rotate(22.5deg);
  animation-delay: -0.875s;
  background: #0d47a1;
}.ldio-yzaezf3dcmj div:nth-child(3) {
  transform: rotate(45deg);
  animation-delay: -0.8125s;
  background: #0d47a1;
}.ldio-yzaezf3dcmj div:nth-child(4) {
  transform: rotate(67.5deg);
  animation-delay: -0.75s;
  background: #0d47a1;
}.ldio-yzaezf3dcmj div:nth-child(5) {
  transform: rotate(90deg);
  animation-delay: -0.6875s;
  background: #0d47a1;
}.ldio-yzaezf3dcmj div:nth-child(6) {
  transform: rotate(112.5deg);
  animation-delay: -0.625s;
  background: #0d47a1;
}.ldio-yzaezf3dcmj div:nth-child(7) {
  transform: rotate(135deg);
  animation-delay: -0.5625s;
  background: #0d47a1;
}.ldio-yzaezf3dcmj div:nth-child(8) {
  transform: rotate(157.5deg);
  animation-delay: -0.5s;
  background: #0d47a1;
}.ldio-yzaezf3dcmj div:nth-child(9) {
  transform: rotate(180deg);
  animation-delay: -0.4375s;
  background: #0d47a1;
}.ldio-yzaezf3dcmj div:nth-child(10) {
  transform: rotate(202.5deg);
  animation-delay: -0.375s;
  background: #0d47a1;
}.ldio-yzaezf3dcmj div:nth-child(11) {
  transform: rotate(225deg);
  animation-delay: -0.3125s;
  background: #0d47a1;
}.ldio-yzaezf3dcmj div:nth-child(12) {
  transform: rotate(247.5deg);
  animation-delay: -0.25s;
  background: #0d47a1;
}.ldio-yzaezf3dcmj div:nth-child(13) {
  transform: rotate(270deg);
  animation-delay: -0.1875s;
  background: #0d47a1;
}.ldio-yzaezf3dcmj div:nth-child(14) {
  transform: rotate(292.5deg);
  animation-delay: -0.125s;
  background: #0d47a1;
}.ldio-yzaezf3dcmj div:nth-child(15) {
  transform: rotate(315deg);
  animation-delay: -0.0625s;
  background: #0d47a1;
}.ldio-yzaezf3dcmj div:nth-child(16) {
  transform: rotate(337.5deg);
  animation-delay: 0s;
  background: #0d47a1;
}
.loadingio-spinner-spinner-2by998twmg8 {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
}
.ldio-yzaezf3dcmj {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-yzaezf3dcmj div { box-sizing: content-box; }

</style>

<style>
  .v-main__wrap{
    overflow: hidden;
  }
</style>